import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import Head from '../components/head'
import BtnLink from '../components/btnLink/btnLink'

import styles from './publications_template.module.scss'

export default ({ data }) => {
  const node = data.allMarkdownRemark.edges[0].node
  const pub = node.frontmatter

  // Find the related image (if there is one):
  let img = null
  if (pub.cover) {
    const imgObj = data.allFile.edges.find(({node}) => {
      return node.base === pub.cover.split('/').slice(-1)[0]
    })
    img = imgObj.node
  } else {
    img = null
  }

  return (
    <Layout>
      <Head title={pub.title} />

      <div className={styles.page_title}>
        <h1>
          <Link to="/publications">&gt;Publications</Link>
        </h1>
      </div>
      <div className={styles.grid_container}>
        <div className={styles.info}>
          <h2>{pub.title}</h2>
          <p className={styles.author}><strong>Author: </strong>{pub.author}</p>
          <p className={styles.date}><strong>Published: </strong>{pub.published}</p>
          <div className={styles.summary} dangerouslySetInnerHTML={{ __html: node.html }}></div>

          {
            pub.isbn && (<p className={styles.isbn}>
              <strong>ISBN:</strong>&nbsp;{pub.isbn}
            </p>)
          }
          {pub.buylink_link && (
          
            <BtnLink text={pub.buylink_text} href={pub.buylink_link} />
          )}
        </div>
        <div className={styles.image}>
          { img && (<Img
            fluid={img.childImageSharp.fluid}
            style={{
              minWidth: 100,
              maxWidth: 350,
            }}
            alt={pub.title}
            title={pub.title}
          />)}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    allMarkdownRemark(filter: {fields: {slug: {eq: $slug}}}) {
      edges {
        node {
          frontmatter {
            author
            buylink_link
            buylink_text
            isbn
            published(formatString: "YYYY")
            title
            cover
          }
          html
          fields {
            slug
          }
        }
      }
    }
    allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)|(gif)|(bmp)/"}}) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
          base
        }
      }
    }
  }
`
